<template>
  <div class="view pa24">
    <el-button
        class="mr10"
        type="primary"
        @click="
        centerDialogVisible = true;
        bdetermine = true;
        editUpdate = false
      "
    >+ 添加轮播</el-button>
    <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :total="total"
    >
      <template v-slot:table>
        <el-table-column type="selection" width="50"/>
        <el-table-column prop="name" align="center" label="轮播图名称" show-overflow-tooltip/>
        <el-table-column align="center" label="图片">
          <template slot-scope="scope">
            <el-image
                style="width: 80px; height: 40px"
                :src="scope.row.imgUrl">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="jumpUrl" align="center" label="跳转链接" show-overflow-tooltip/>
        <el-table-column align="center" label="排序">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="数值越大，排序越靠前" placement="right-start">
              <el-input v-model="scope.row.sort"  min="0" @input="scope.row.sort=scope.row.sort.replace(/^0(0+)|[^\d]+/g,'')"  placeholder="数值越大，排序越靠前" @change="saveSort(scope.row)"/>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="uploadTime" align="center" label="状态" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-switch
                @change="saveStatus(scope.row)"
                v-model="scope.row.status"
                active-color="#51CDCB"
                inactive-color="#dadde5">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
            <!--            <el-button-->
            <!--                type="text"-->
            <!--                @click="-->
            <!--                edit(scope.row, false);-->
            <!--                bEdit = true;-->
            <!--              "-->
            <!--            >详情</el-button-->
            <!--            >-->
            <el-button
                type="text"
                @click="
                edit(scope.row, true);
                bEdit = false;
                editUpdate = true
              "
            >编辑</el-button
            >
            <!-- <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              @onConfirm="del(scope.row.videoId)"
              icon="el-icon-info"
              icon-color="red"
              title="确定要删除吗？"
            >
              <el-button
                slot="reference"
                class="ml10"
                style="color: #fd634e"
                type="text"
                >删除</el-button
              >
            </el-popconfirm> -->
            <customPopconfirm
                confirm-button-text="确定"
                k              cancel-button-text="取消"
                @confirm="del(scope.row.imgId)"
                icon="el-icon-info"
                icon-color="red"
                title="确定要删除吗？">
              <el-button
                  slot="reference"
                  class="ml10"
                  style="color: #fd634e"
                  type="text"
              >删除</el-button
              >
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <!--    :destroy-on-close="true"-->
    <el-dialog
        title="添加轮播"
        :visible.sync="centerDialogVisible"
        :modal-append-to-body="false"
        width="50%"
        center
        @close="close"
    >
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="150px"
      >
        <el-form-item label="轮播名称" prop="title">
          <el-input clearable v-model="ruleForm.title" maxlength="50" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="图片" prop="coverImg">
          <el-upload :action="$store.state.uploadingUrl"
                     list-type="picture-card"
                     :class="{ disabled: uploadDisabled }"
                     :on-remove="handleRemove"
                     :on-success="handleSuccess"
                     :file-list="fileList"
                     :limit="1">
            <i slot="default" class="el-icon-plus"></i>
            <div slot="tip" class="el-upload__tip">
              <!--              上传图片的最佳尺寸：4:9，其他尺寸会影响页面效果，格式png，jpeg，jpg。大小不超过2M-->
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input clearable v-model="ruleForm.sort" min="0" @input="ruleForm.sort=ruleForm.sort.replace(/^0(0+)|[^\d]+/g,'')" maxlength="11" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="isState">
          <el-radio-group v-model="ruleForm.isState">
            <el-radio :label="0">显示</el-radio>
            <el-radio :label="1">不显示</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="跳转链接" prop="skipLink">
          <el-radio-group v-model="ruleForm.skipLink">
            <el-radio :label="1">跳转小程序内部页面</el-radio>
            <el-radio :label="2">跳转到网页</el-radio>
            <el-radio :label="3">跳转到外部小程序页面</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="ruleForm.skipLink == 1" style="position: relative">
          <el-select
              clearable
              v-model="ruleForm.link"
              style="width: 100%"
              placeholder="请选择内部页面"
          >
            <el-option
                v-for="(item) in linkList"
                :key="item.linkId"
                :label="item.linkTitle　+ '　' + item.linkName"
                :value="item.linkId"
            />
          </el-select>
          <!--          <span style="position: absolute;right: 2px;top:2px;background: #FFFFFF;height: 30px;width: 100px;text-align: center;cursor: pointer">选择</span>-->
        </el-form-item>
        <el-form-item v-if="ruleForm.skipLink == 2" style="position: relative">
          <el-input clearable v-model="ruleForm.link" placeholder="请输入URL..." maxlength="255" show-word-limit></el-input>
        </el-form-item>
        <el-form-item v-if="ruleForm.skipLink == 3" label="小程序APPID">
          <el-input clearable v-model="ruleForm.appId" maxlength="50" show-word-limit></el-input>
        </el-form-item>
        <el-form-item v-if="ruleForm.skipLink == 3" label="跳转路径">
          <el-input clearable v-model="ruleForm.link" maxlength="255" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine" v-if="bdetermine"
        >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { mapState } from 'vuex';
import customPopconfirm from "@/components/common/customPopconfirm";
import {queryCustomPageAll} from "@/api/content";
import {addAttractInvestmentImg,queryAttractInvestmentImgPage,updateAttractInvestmentImg,delAttractInvestmentImg} from "@/api/chinaMerchants";
let ac;
export default {
  name:'swiperStep',
  data() {
    return {
      linkList:[
        {linkId:2,linkName:'/pages/home/ruhui/initiation',linkTitle:'我要入会'},
        {linkId:3,linkName:'/pages/home/helpDonation',linkTitle:'赞助捐赠'},
        {linkId:4,linkName:'/pages/home/goodMembers',linkTitle:'优秀会员'},
        {linkId:5,linkName:'/pages/hzfc/index',linkTitle:'会长风采'},
        {linkId:6,linkName:'/pages/home/afficheList',linkTitle:'公告列表'},
        {linkId:7,linkName:'/pages/home/messageList',linkTitle:'资讯列表'},
        {linkId:8,linkName:'/pages/addressBook/index/main',linkTitle:'通讯录'},
        {linkId:9,linkName:'/pages/associationMy/informMsg',linkTitle:'消息通知'},
        {linkId:10,linkName:'/pages/associationMy/myPublish',linkTitle:'我的发布'},
        {linkId:11,linkName:'/pages/associationMy/myDating',linkTitle:'我的约局'},
        {linkId:12,linkName:'/pages/associationMy/payWill',linkTitle:'会费缴纳'},
        {linkId:13,linkName:'/pages/associationMy/feedBack',linkTitle:'意见反馈'},
        {linkId:14,linkName:'/pages/associationMy/aboutUs',linkTitle:'关于我们'},
      ],
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      goodsList: [],
      goodsListCurrentPage: 1, //当前页
      goodsListPageSize: 10, //显示条数
      goodsListLoading: false, //表格加载
      goodsListTotal: 0, //总条数
      centerDialogVisible: false,
      dialogDetailVisible: false,
      bEdit: false,
      bdetermine: true,
      editUpdate:true,
      isUpdate:true,
      fileList: [],
      userInfo: this.$store.state.loginRoot.userInfo,
      ruleForm: {
        title: "",
        sort: "",
        isState: 0,
        skipLink: 1,
        link:'',
        coverImg:'',
        appId:''
      },
      rules: {
        title: [{ required: true, message: "请输入轮播名称", trigger: "blur" }],
        coverImg: [{ required: true, message: "请上传图片" }],
        sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
        isState: [{ required: true, message: "请选择状态", trigger: "blur" }],
        skipLink: [{ required: true, message: "请选择跳转链接", trigger: "blur" }],
      },
      jurisdictionA:'',
      jurisdictionB:'',
      imgId:'',
      ossUrl: this.$store.state.ossUrl,
    };
  },
  components: {
    commonTable,
    customPopconfirm
  },
  computed: {
    ...mapState(['routerArr']),
    uploadDisabled() {
      return this.fileList.length !== 0;
    },
  },
  watch:{
    routerArr(val){
      console.log(val)
      this.jurisdictionA = val.includes('080')
      this.jurisdictionB = val.includes('081')
      if(this.jurisdictionA || this.jurisdictionB){
        this.queryPage()
      }
    }
  },
  mounted(){
    this.queryCustom()
    this.queryPage();
  },

  methods: {
    async queryCustom () {
      let data = {
        associationId: localStorage.getItem("associationId"),
      };
      try {
        const result = await queryCustomPageAll(data);
        let list = result.data;
        for (let i in list) {
          this.linkList.push({linkId:15+i,linkName:list[i].pageUrl,linkTitle:list[i].pageName})
        }
      } catch (error) {
      }
    },
    async queryPage() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        dataFrom:1
      };
      try {
        this.loading = true;
        const result = await queryAttractInvestmentImgPage(data);
        this.loading = false;
        const { total, list } = result.data.pageInfo;
        for (let i in list) {
          if (list[i].status == 0) {
            list[i].status = true
          }else {
            list[i].status = false
          }
        }
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.tableData = []
        this.loading = false;
      }
    },
    handleRemove(file, fileList) {
      this.fileList = []
      this.ruleForm.coverImg = "";
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPage();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPage();
    },
    handleSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.coverImg = this.ossUrl + file.response.data
      this.fileList = fileList;
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    edit(val, b) {
      let data = JSON.parse(JSON.stringify(val));
      this.centerDialogVisible = true;
      this.ruleForm.title = data.name;
      this.ruleForm.sort = data.sort;
      this.ruleForm.isState = data.status?0:1;
      this.ruleForm.skipLink = data.jumpType;
      this.ruleForm.link = data.jumpUrl;
      this.ruleForm.coverImg = data.imgUrl;
      this.ruleForm.appId = data.jumpAppId;
      this.fileList = [{ url: data.imgUrl, uid: 1 }];
      this.bdetermine = b;
      this.bEdit = b;
      this.imgId = data.imgId
    },
    /**@method 添加 */
    determine() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              associationId: localStorage.getItem("associationId"),
              name:this.ruleForm.title,
              imgUrl:this.ruleForm.coverImg,
              sort:this.ruleForm.sort,
              status:this.ruleForm.isState,
              jumpType:this.ruleForm.skipLink,
              jumpUrl:this.ruleForm.link,
              dataFrom:1
            };
            if (this.ruleForm.skipLink == 1) {
              for (let i in this.linkList) {
                if (this.linkList[i].linkId == this.ruleForm.link) {
                  data.jumpUrl = this.linkList[i].linkName
                }
              }
              if (data.jumpUrl == '') {
                this.$message({
                  message: "请选择内部链接",
                  type: "error",
                });
              }
            }
            if (this.ruleForm.skipLink == 3) {
              data.jumpAppId = this.ruleForm.appId
            }
            this.$message({
              message: "正在保存",
            });
            if (this.editUpdate) {
              data.imgId = this.imgId
              await updateAttractInvestmentImg(data);
            } else {
              await addAttractInvestmentImg(data);
            }
            this.centerDialogVisible = false;
            this.$message.closeAll();
            this.$message({
              message: "保存成功",
              type: "success",
            });
            await this.queryPage();
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**@method 删除
     * @param {String} val - 文章id
     */
    async del(val) {
      try {
        await delAttractInvestmentImg({ imgId: val });
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    async saveSort(row){
      if(row && row.sort != ""){
        let ajax = {
          sort:row.sort,
          imgId:row.imgId
        }
        const result = await updateAttractInvestmentImg(ajax);
        console.log("result:",result)
        this.$message({
          type: "success",
          message: "保存成功",
        });
        await this.queryPage();
      }else{
        this.$message({
          type: "error",
          message: "排序不能为空",
        });
      }
    },
    async saveStatus(row){
      if(row){
        let ajax = {
          status:row.status?0:1,
          imgId:row.imgId
        }
        const result = await updateAttractInvestmentImg(ajax);
        console.log("result:",result)
        this.$message({
          type: "success",
          message: "保存成功",
        });
        await this.queryPage();
      }else{
        this.$message({
          type: "error",
          message: "排序不能为空",
        });
      }
    },
    /**@method 模态框关闭的回调 */
    close() {
      (this.ruleForm = {
        title: "",
        sort: "",
        isState: 0,
        skipLink: 0,
        link:'',
        coverImg:'',
        appId:''
      }),
          this.centerDialogVisible = false;
      this.fileList = []
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  //line-height: 175px;
  text-align: center;
}
/deep/ .avatar-coverImg .el-upload--text {
  width: 100px;
  height: 100px;
}
/deep/ .avatar-coverImg .avatar-uploader-icon {
  line-height: 100px;
}
.select-goods-list {
  margin-bottom: 20px;
  border-radius: 40px;
  box-sizing: border-box;
  padding: 0 20px;
  background: #ffffff;
  transition: all 0.4s;
}
.select-goods-list:hover {
  background: #f5f5f6;
  transition: all 0.4s;
  cursor: pointer;
}
/deep/ .disabled .el-upload--picture-card {
  display: none;
}
</style>





